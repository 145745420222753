<template>
  <b-row>
    <b-col
      cols="5"
      align="left"
      class="mt-3"
    >
      <div class="product-box">
        <div class="product-img d-flex">
          <img
            v-if="activity.photo"
            class="object-fit-img"
            height="130"
            width="180"
            :src="activity.id ? $filePath(activity.photo.contentUrl): localUrlLink(activity.photo)"
          />
          <img
            v-else
            class="pointer"
            height="130"
            src="@/assets/icons/playground/new/step-1/upload-image.svg"
            alt
            width="180"
            @click="uploadPhoto"
          />
          <div
            v-if="activity.photo"
            class="product-hover"
          >
            <ul>
              <li
                class="pointer"
                @click="uploadPhoto"
              >
                <button
                  class="btn"
                  type="button"
                >
                  <i class="icon-upload"/>
                </button>
              </li>
              <li
                class="pointer"
                @click="deletePhoto"
              >
                <button
                  class="btn"
                  type="button"
                >
                  <i class="icon-trash"/>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      align="left"
    >
      <b-row
        class="mt-1"
        no-gutters
      >
        <b-col cols="12">
          <label class="col-form-label-sm field-name-class mb-0">
            {{ $t(`admin.activity.form.name`) }}
          </label>
        </b-col>
        <b-col cols="12">
          <d-text-field
            v-model.trim="activity.name"
            :rules="validation.text"
            size="sm"
            place-holder="admin.activity.form.place-holder-name"
            error-text="validation.text-input-three-characters"
          />
        </b-col>
      </b-row>
    </b-col>
    <levels-tab
      :activity="activity"
      @on:level:created-or-updated="$emit('on:level:created-or-updated')"
      @on:delete-level="$emit('on:delete-level')"
    />
  </b-row>
</template>

<script>
import {fromIdToIriReference} from "@/utils/form";
import {_delete} from "@api/services/httpService";
import LevelsTab from "@custom/activities/level/LevelsTab";
import {URL_ACTIVITIES} from "@api/services/activities/activities.api";

export default {
  components: {LevelsTab},
  data: () => ({
    isBusy: false
  }),
  props: {
    activity: {
      type: Object,
      default: null,
    },
    validation: {
      type: Object,
      default: null
    }
  },
  methods: {
    deletePhoto() {
      return new Promise((resolve, reject)=> {
        this.getDeleteRequest()
          .then((response) => {
            this.activity.photo = response ? response.data : null;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    uploadPhoto() {
      if(!this.isBusy) {
        this.isBusy = true;
        if (this.activity.id === null) {
          this.$upload(this.uploaded,
            {
              entry: 'activity',
              url: null,
              target: ''
            })
        } else {
          this.deletePhoto()
            .then(() => {
              this.$upload(this.uploaded, {
                entry: 'activity',
                url: fromIdToIriReference(URL_ACTIVITIES, this.activity.id),
                target: 'postActivityPhoto'
              });
            })
        }
      }

    },
    localUrlLink(photo) {
      return URL.createObjectURL(photo);
    },
    uploaded(file) {
      this.activity.photo = file;

      if (this.activity.id) {
        this.isBusy = false;
        this.$emit('on:activity-photo:updated', {activity: this.activity, photo: file});
      }
    },
    getDeleteRequest() {
      return this.activity.photo ? _delete(this.activity.photo['@id']) : Promise.resolve(false);
    }
  }
}
</script>
<style scoped lang="scss">

@media only screen and (max-width: 991px) {
  .product-box .product-img .product-hover {
    height: 130px;
    width: 180px;
  }
}

@media only screen and (min-width: 991px) {
  .product-box .product-img .product-hover {
    height: 130px;
    width: 180px;
  }
}
</style>
